import {Base_Props} from '../App/App'
import {Client_Event_Name} from '../../../common/src/state/Event_And_State_Names'
import './Player_Game_Setup.css'
import {Standard_Page_Layout} from '../Components/Standard_Page_Layout'

export function Player_Game_Setup(props: Base_Props) {
	const {
		send,
		context: {
			game_name,
			teams,
			teams_enabled,
			players,
			player_name,
			player_secret,
		},
	} = props

	const join_team = (team_name: string) => {
		send({
			type: Client_Event_Name.join_team,
			detail: {team_name, player_name, player_secret},
		})
		return false
	}
	const buzz = () =>
		send({
			type: Client_Event_Name.test_buzz,
			detail: {game_name, player_name, player_secret},
		})
	const quit = () =>
		send({
			type: Client_Event_Name.quit,
			detail: {game_name, player_name, player_secret},
		})

	const header = (
		<>
			<div className="page_header">
				{teams_enabled ? 'Pick a team' : 'Pre game setup'}
			</div>
			<div className="sub_header">
				game: {game_name}, player: {player_name}
			</div>
		</>
	)

	const content = (
		<>
			<div className="game_setup_content">
				{teams_enabled && teams.list.length === 0 && (
					<div>Waiting for host to add some teams..</div>
				)}
				<div className="teams_table">
					{teams.list.map(team => (
						<div className="team_container">
							<div className="team_left_side">
								<div className="team_name">{team.name}</div>
								<button
									data-testid={`join team ${team.name}`}
									title={`join team ${team.name}`}
									onClick={() => join_team(team.name)}>
									join
								</button>
							</div>
							<div className="team_right_side">
								<div className="muted_label">Members:</div>
								<div className="team_players_box">
									{team.members.list.map(member => (
										<div className="player_item" data-player-item={member.name}>
											{member.name}
										</div>
									))}
								</div>
							</div>
						</div>
					))}
				</div>
				{!teams_enabled && (
					<>
						<h2>Players</h2>
						{players.list.map(player => (
							<div>
								<div className="player_item" data-player-item={player.name}>
									{player.name}
								</div>
								<div
									className={
										player.connected
											? 'socket_connected'
											: 'socket_disconnected'
									}></div>
							</div>
						))}
					</>
				)}
			</div>
		</>
	)

	const controls = (
		<>
			<button onClick={buzz}>Test buzzer</button>
			<button className="negative" onClick={quit}>
				Quit game
			</button>
		</>
	)

	return (
		<Standard_Page_Layout
			header={header}
			content={content}
			controls={controls}
		/>
	)
}
