import {Base_Props} from '../App/App'
import './Connection_Closed.css'
import {Websocket_Event_Names} from '../State/websockets_machine'
import {Client_Event_Name} from '../../../common/src/state/Event_And_State_Names'

export function Connection_Closed(props: Base_Props) {
	const {game_name} = props.context

	const {send, context} = props

	const retry_connection = () =>
		send({type: Websocket_Event_Names.connect_websocket})
	const reset_game = () => send({type: Client_Event_Name.reset_game})

	return (
		<div className="page">
			<header className="page_header">Problem!</header>
			<header className="sub_header">game: {game_name}</header>
			<section>
				{context.game_name ? (
					<button onClick={retry_connection}>Reconnect</button>
				) : (
					<button onClick={reset_game}>Reset game</button>
				)}
			</section>
		</div>
	)
}
