import './Player_Finished.css'
import {Base_Props} from '../App/App'
import {Client_Event_Name} from '../../../common/src/state/Event_And_State_Names'
import {Standard_Page_Layout} from '../Components/Standard_Page_Layout'
import {Game_Results, get_game_results} from '../State/derivatives'

export function Player_Finished(props: Base_Props) {
	const {game_name, teams_enabled} = props.context
	const {send} = props

	const send_quit = () => send({type: Client_Event_Name.quit})

	const results = get_game_results(props.context)

	const generate_results_content = (results: Game_Results) => {
		if (teams_enabled) {
			if (results.is_draw) {
				return (
					<>
						<div className="results_box">
							<div className="results_box_header">It's a draw!</div>
							<div className="results_box_winners">
								Congratulations to{' '}
								<div className="winner_list">
									{results.team_winners.map(team => team.name).join(', ')}
								</div>
							</div>
						</div>
					</>
				)
			} else {
				return (
					<>
						<div className="results_box">
							<div className="results_box_header">We have a winner!</div>
							<div className="results_box_winners">
								Congratulations to{' '}
								<div className="winner_list">
									{results.team_winners[0].name}
								</div>
							</div>
						</div>
					</>
				)
			}
		} else {
			if (results.is_draw) {
				return (
					<>
						<div className="results_box">
							<div className="results_box_header">It's a draw!</div>
							<div className="results_box_winners">
								Congratulations to{' '}
								<div className="winner_list">
									{results.player_winners.map(player => player.name).join(', ')}
								</div>
							</div>
						</div>
					</>
				)
			} else {
				return (
					<>
						<div className="results_box">
							<div className="results_box_header">We have a winner!</div>
							<div className="results_box_winners">
								Congratulations to{' '}
								<div className="winner_list">
									{results.player_winners[0].name}
								</div>
							</div>
						</div>
					</>
				)
			}
		}
	}

	const header = (
		<>
			<div className="page_header">Finished</div>
			<div className="sub_header">
				congratulations to the winner of game: {game_name}!
			</div>
		</>
	)
	const content = generate_results_content(results)

	const controls = (
		<>
			<button onClick={send_quit}>Exit</button>
		</>
	)
	return (
		<Standard_Page_Layout
			header={header}
			content={content}
			controls={controls}
		/>
	)
}
