import {ReactNode} from 'react'
import './Modal_Focus.css'

interface Props {
	children?: ReactNode
	center?: boolean
}

export function Modal_Focus(props: Props) {
	return (
		<>
			<div className="modal_focus_outer">
				<div className="modal_focus">
					<div className={props.center ? 'center' : ''}>{props.children}</div>
					<div className="modal_focus_done_button">
						<button
							tabIndex={-1}
							form=""
							onClick={e => (e.target as HTMLElement).blur()}>
							Done
						</button>
					</div>
				</div>
			</div>
		</>
	)
}
