import './Standard_Page_Layout.css'

type Standard_Page_Layout_Props = {
	header: JSX.Element
	content: JSX.Element
	controls: JSX.Element
}

export function Standard_Page_Layout(props: Standard_Page_Layout_Props) {
	const {header, content, controls} = props

	return (
		<div className="page">
			<div className="header_section">{header}</div>
			<section className="page_content_area">{content}</section>
			<div className="controls_section">{controls}</div>
		</div>
	)
}
