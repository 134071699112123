import './Host_Setup_Game_Structure.css'
import {
	Quiz_Question,
	Round_Based_Game_Structure,
} from '../../../common/src/models/Game_Structure'
import {
	Client_Event_Name,
	Host_Event_Name,
} from '../../../common/src/state/Event_And_State_Names'
import {Base_Props} from '../App/App'
import {Standard_Page_Layout} from '../Components/Standard_Page_Layout'
import {Modal_Focus} from '../Components/Modal_Focus/Modal_Focus'
import {MouseEvent, useEffect, useState} from 'react'
import { Help_Tips, show_help_tip } from '../Help_Tips/Help_tips'

export function Host_Setup_Game_Structure(props: Base_Props) {
	const {send, context} = props

	const structure = context.game_structure

	const [help_tip_seen, set_help_tip_seen] = useState(false)

	useEffect(() => {
		if (!help_tip_seen && context.is_trial) {
			set_help_tip_seen(true)
			show_help_tip('rounds_and_questions_tips')
		}
	}, [help_tip_seen])

	const add_sample_questions = () => send({type: Host_Event_Name.add_sample_questions})

	const add_round = () =>
		send({type: Host_Event_Name.add_round, detail: {round_name: ``}})
	const remove_round = (round_num: number) =>
		send({
			type: Host_Event_Name.remove_round,
			detail: {round_number: round_num},
		})
	const add_question = (round_num: number) =>
		send({
			type: Host_Event_Name.add_question,
			detail: {
				round_number: round_num,
				question_text: '',
				answer_text: '',
				points: 10,
				incorrect_points: -5,
			},
		})
	const update_structure = () =>
		send({
			type: Host_Event_Name.update_game_structure,
			detail: {game_structure: structure},
		})
	const send_cancel_game = () => send({type: Client_Event_Name.cancel_game})

	// const swap_questions = (round_num: number, question_index_1: number, question_index_2: number) => {
	//     send({
	//         type: Host_Event_Name.swap_questions,
	//         detail: {
	//             round_num,
	//             question_index_1,
	//             question_index_2
	//         }
	//     })
	//     update_structure()
	// }

	// const swap_rounds = (round_1_index: number, round_2_index: number) => {
	//     send({
	//         type: Host_Event_Name.swap_rounds,
	//         detail: {
	//             round_1_index,
	//             round_2_index,
	//         }
	//     })
	// }

	const remove_question = (
		e: MouseEvent<HTMLButtonElement>,
		round_num: number,
		question_index: number,
	) => {
		;(e.target as HTMLElement).blur()
		structure.rounds[round_num].questions.splice(question_index, 1)
		update_structure()
	}
	const generate_round_rows = (structure: Round_Based_Game_Structure) => {
		return structure.rounds.map((round, round_num) => {
			return (
				<div className="game_structure_table">
					<div
						className="structure_round_container"
						data-testId={`round row ${round_num}`}>
						<Modal_Focus>
							<div className="form_row">
								<label>Round {round_num + 1}</label>
								<input
									className=""
									data-testId={`round name ${round_num}`}
									value={round.name}
									placeholder="new round name"
									onChange={event =>
										send({
											type: Host_Event_Name.set_round_name,
											detail: {round_num, text: event.target.value},
										})
									}
									onBlur={update_structure}></input>
								{/* <button className="clicker hide_in_modal" data-testId={`move round up ${round_num}`} disabled={round_num === 0} onClick={() => swap_rounds(round_num - 1, round_num)}>↑</button> */}
								{/* <button className="clicker hide_in_modal" data-testId={`move round down ${round_num}`} disabled={round_num === structure.rounds.length - 1}  onClick={() => swap_rounds(round_num, round_num + 1)}>↓</button> */}
								<button
									className="clicker hide_in_modal delete"
									data-testId={`delete round ${round_num}`}
									onClick={() => remove_round(round_num)}>
									X
								</button>
							</div>
						</Modal_Focus>
						<div>{generate_question_rows(round.questions, round_num)}</div>
						<button
							data-testId={`add question ${round_num}`}
							onClick={() => add_question(round_num)}>
							Add a question
						</button>
					</div>
				</div>
			)
		})
	}

	const generate_question_rows = (
		questions: Quiz_Question[],
		round_num: number,
	) => {
		return questions.map((question, question_index) => (
			<Modal_Focus>
				<div
					className="structure_question_row"
					data-testId={`question row ${round_num}|${question_index}`}>
					<div className="structure_question_question">
						<label>Q {question_index + 1}</label>
						<input
							className="wide clickedit"
							value={question.question_text}
							placeholder="question text"
							onChange={event =>
								send({
									type: Host_Event_Name.set_question_text,
									detail: {round_num, question_index, text: event.target.value},
								})
							}
							onBlur={update_structure}></input>
						{/* <button className="clicker hide_in_modal" onClick={() => swap_questions(round_num, question_index - 1, question_index)}>↑</button> */}
						{/* <button className="clicker hide_in_modal" onClick={() => swap_questions(round_num, question_index, question_index + 1)}>↓</button> */}
						<span className="show_in_modal">
							<button
								className="clicker delete"
								onClick={e => remove_question(e, round_num, question_index)}>
								X
							</button>
						</span>
					</div>
					<input
						className="show_in_modal clickedit"
						value={question.answer_text}
						placeholder="answer text"
						onChange={event =>
							send({
								type: Host_Event_Name.set_answer_text,
								detail: {round_num, question_index, text: event.target.value},
							})
						}
						onBlur={update_structure}></input>
					<div className="show_in_modal">
						<div className="structure_question_points">
							<label>Points:</label>
							<span className="correct_tick">✔</span>
							<input
								data-testId="correct points"
								className="narrow clickedit"
								type="number"
								value={question.points}
								placeholder="points"
								onChange={event =>
									send({
										type: Host_Event_Name.set_question_points,
										detail: {
											round_num,
											question_index,
											points: event.target.value,
										},
									})
								}
								onBlur={update_structure}></input>
							<span className="incorrect_cross">✗</span>
							<input
								data-testId="incorrect points"
								className="narrow clickedit"
								type="number"
								value={question.incorrect_points}
								placeholder="deduct"
								onChange={event =>
									send({
										type: Host_Event_Name.set_question_deduct_points,
										detail: {
											round_num,
											question_index,
											points: event.target.value,
										},
									})
								}
								onBlur={update_structure}></input>
						</div>
					</div>
				</div>
			</Modal_Focus>
		))
	}

	const header = (
		<>
			<div className="page_header">Game structure</div>
			<div className="sub_header" data-help-tip='rounds_and_questions_tips'>Add rounds and questions here</div>
			<div className="hidden">
				<input data-testid="game_code" value={props.context.game_code} />
			</div>
			{props.context.is_trial ? (
					<Help_Tips 
						id='rounds_and_questions_tips'
						button_action={add_sample_questions}
						button_text='Add sample questions'
					>
						<div>
							<p>
								Normally you add rounds and questions here. 
								To speed things up lets just add a bunch of sample rounds and questions here.
							</p>
							<p>
								Click on the questions to view and edit the questions and answers. Click "Next: prepare game" when you're finished.
							</p>
						</div>
					</Help_Tips>
			) : <></>}
		</>
	)

	const content = (
		<>
			<div className="host_game_structure">
				{generate_round_rows(context.game_structure)}
			</div>
		</>
	)

	const controls = (
		<>
			<button onClick={add_round}>Add a round</button>
			<button
				className="alternative"
				onClick={() => send({type: Host_Event_Name.setup_game})}>
				Next: prepare game
			</button>
			<button className="negative" onClick={send_cancel_game}>
				Cancel game
			</button>
		</>
	)

	return (
		<Standard_Page_Layout
			header={header}
			content={content}
			controls={controls}
		/>
	)
}
