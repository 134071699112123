import {Base_Props} from '../App/App'
import {useForm} from 'react-hook-form'
import './Host_Game_Setup.css'
import {
	Client_Event_Name,
	Host_Event_Name,
} from '../../../common/src/state/Event_And_State_Names'
import {Standard_Page_Layout} from '../Components/Standard_Page_Layout'
import toast from 'react-hot-toast'
import { useEffect, useState } from 'react'
import { Help_Tips, show_help_tip } from '../Help_Tips/Help_tips'

type Add_Team_Form_Values = {
	team_name: string
	game_name: string
}

export function Host_Game_Setup(props: Base_Props) {
	const {
		game_name,
		game_code,
		teams,
		players,
		is_trial,
		teams_enabled,
		questions_and_rounds_enabled,
	} = props.context

	const [teams_help_tip_seen, set_teams_help_tip_seen] = useState(false)

	useEffect(() => {
		if (!teams_help_tip_seen && is_trial) {
			set_teams_help_tip_seen(true)
			show_help_tip('game_setup_tip')
		}
	}, [teams_help_tip_seen])

	const {send} = props
	const {
		register,
		resetField,
		handleSubmit,
		formState: {errors},
	} = useForm<Add_Team_Form_Values>()
	const onSubmit = handleSubmit(data => {
		send({type: Client_Event_Name.add_team, detail: data})
		resetField('team_name')
	})
	const remove_team = (team_name: string) =>
		send({type: Host_Event_Name.remove_team, detail: {team_name}})

	const remove_player = (player_name: string) =>
		send({type: Host_Event_Name.remove_player, detail: {player_name}})

	const start_game = () => send({type: Client_Event_Name.start_game})

	const link =
		window.location.protocol +
		'//' +
		window.location.host +
		`#${game_code.toUpperCase()}`

	const copy_link_to_clipboard = () => {
		navigator.clipboard.writeText(link)
		toast.success(`link copied to clipboard`, {
			position: 'top-center',
		})
	}

	const share_link = () => {
		const data = {
			text: `Join my game ${game_name} on Quiz-Buzz.com!`,
			url: link,
		}
		if (navigator.canShare && navigator.canShare(data)) {
			navigator.share(data)
		} else {
			toast.error(
				`sharing links isn't available in this browser sorry, use the clipboard option below`,
				{
					position: 'top-center',
				},
			)
		}
	}

	const send_cancel_game = () => send({type: Client_Event_Name.cancel_game})

	// generate a map of which teams players are in:
	const player_teams: {[index: string]: string} = {}
	teams.list.map(team =>
		team.members.list.map(player => (player_teams[player.name] = team.name)),
	)

	const generate_teams_list = () => {
		if (!teams_enabled) return <></>

		return teams.list.map(team => (
			<div className="team_container">
				<div className="team_left_side">
					<div className="team_name">{team.name}</div>
					<button
						className="negative mini_text"
						data-testid={`remove team ${team.name}`}
						title={`remove team ${team.name}`}
						onClick={() => remove_team(team.name)}>
						<span>
							remove
							<br />
							team
						</span>
					</button>
				</div>
				<div className="team_right_side">
					<div>Team members:</div>
					<div className="team_players_box">
						{team.members.list.map(member => (
							<div className="player_item" data-player-item={member.name}>
								{member.name}
							</div>
						))}
					</div>
				</div>
			</div>
		))
	}

	const header = (
		<>
			<header className="page_header">
				{teams_enabled ? 'Set up teams' : 'Set up players'}
			</header>
			<header className="sub_header">game: {game_name}</header>
			<div className="hidden">
				<input data-testid="game_code" value={game_code} />
			</div>
		</>
	)

	const content = (
		<>
			<div className="host_game_setup">
				<div data-help-tip="game_setup_tip">
					{teams_enabled ? (
						<div className="add_team_section">
							<form onSubmit={onSubmit}>
								<div className="">
									<div
										className={
											errors?.team_name ? 'validation_warn_attach' : 'hidden'
										}>
										limit: 20 characters
									</div>
									<input
										className={errors?.team_name ? 'validation_warn' : ''}
										{...register('team_name', {required: true, maxLength: 20})}
										placeholder="new team name"
									/>
									<input {...register('game_name')} hidden value={game_name} />
									<button type="submit">add team</button>
								</div>
							</form>
							<h2>Teams</h2>
							{generate_teams_list()}
						</div>
					) : (
						<></>
					)}
				</div>
					{props.context.is_trial ? (
						<Help_Tips 
							id='game_setup_tip'
						>
							<div>
								<p>
									{teams_enabled ? 
									"Add teams in this top box by entering the team name and then clicking add team, once your players join the game they can choose which team they join, you'll see all their names appear as they join."
									:
									""
									}
									
								</p>
								<p>
									You need to give your players the join link. Either copy it with the copy link button,
									or click share to use your phone's built in sharing.
								</p>
								<p>
									If you're just trying this out on your own, try using the join link on another phone or on
									a computer browser to add a player in to the game.
								</p>
							</div>
						</Help_Tips>
						) : <></>}



				<div className="host_setup_players_section">
					<h2>Players</h2>
					{players.list.length === 0 ? (
						<span>
							<div className="flex_row two_buttons text_center">
								<button className="icon_button share_button" onClick={share_link}>
									<svg
										fill="#fff"
										stroke="#fff"
										width="1.5em"
										height="1.5em"
										viewBox="0 0 50 50"
										version="1.1"
										xmlns="http://www.w3.org/2000/svg">
										<path d="M37.75,20.25 C38.6681734,20.25 39.4211923,20.9571103 39.4941988,21.8564728 L39.5,22 L39.5,36.25 C39.5,39.3517853 37.0439828,41.879937 33.9705557,41.9958479 L33.75,42 L14.25,42 C11.1482147,42 8.62006299,39.5439828 8.50415208,36.4705557 L8.5,36.25 L8.5,22 C8.5,21.0335017 9.28350169,20.25 10.25,20.25 C11.1681734,20.25 11.9211923,20.9571103 11.9941988,21.8564728 L12,22 L12,36.25 C12,37.440864 12.9251616,38.4156449 14.0959512,38.4948092 L14.25,38.5 L33.75,38.5 C34.940864,38.5 35.9156449,37.5748384 35.9948092,36.4040488 L36,36.25 L36,22 C36,21.0335017 36.7835017,20.25 37.75,20.25 Z M23.4989075,6.26787884 L23.6477793,6.25297693 L23.6477793,6.25297693 L23.8225053,6.25140103 L23.8225053,6.25140103 L23.9770074,6.26441014 L23.9770074,6.26441014 L24.1549097,6.29667263 L24.1549097,6.29667263 L24.223898,6.31492315 L24.223898,6.31492315 C24.4192207,6.36884271 24.6069182,6.4577966 24.7773762,6.58126437 L24.8968901,6.67628678 L24.8968901,6.67628678 L24.989825,6.76256313 L32.7679996,14.5407377 C33.4514171,15.2241552 33.4514171,16.3321939 32.7679996,17.0156115 C32.1247831,17.6588279 31.1054316,17.6966642 30.4179639,17.1291203 L30.2931259,17.0156115 L25.5,12.222 L25.5,31.5 C25.5,32.4181734 24.7928897,33.1711923 23.8935272,33.2441988 L23.75,33.25 C22.8318266,33.25 22.0788077,32.5428897 22.0058012,31.6435272 L22,31.5 L22,12.226 L17.2116504,17.0156115 C16.5684339,17.6588279 15.5490824,17.6966642 14.8616148,17.1291203 L14.7367767,17.0156115 C14.0935602,16.372395 14.055724,15.3530435 14.6232679,14.6655758 L14.7367767,14.5407377 L22.488804,6.78678454 C22.5446792,6.72871358 22.6045271,6.67449255 22.6679103,6.62455868 L22.7812362,6.54379243 L22.7812362,6.54379243 C22.8189499,6.51724 22.858413,6.49312256 22.8988638,6.47056335 L22.9176605,6.46138558 C23.0947495,6.36422067 23.2909216,6.29776289 23.4989075,6.26787884 Z"></path>
									</svg>
									<div>Share the join link</div>
								</button>
								<button
									className="icon_button share_button"
									onClick={copy_link_to_clipboard}>
									<svg
										fill="#000000"
										width="1.5em"
										height="1.5em"
										viewBox="0 0 36 36"
										version="1.1"
										preserveAspectRatio="xMidYMid meet"
										xmlns="http://www.w3.org/2000/svg">
										<title>copy-to-clipboard-line</title>
										<path
											fill="currentColor"
											stroke="currentColor"
											d="M22.6,4H21.55a3.89,3.89,0,0,0-7.31,0H13.4A2.41,2.41,0,0,0,11,6.4V10H25V6.4A2.41,2.41,0,0,0,22.6,4ZM23,8H13V6.25A.25.25,0,0,1,13.25,6h2.69l.12-1.11A1.24,1.24,0,0,1,16.61,4a2,2,0,0,1,3.15,1.18l.09.84h2.9a.25.25,0,0,1,.25.25Z"></path>
										<path
											fill="currentColor"
											stroke="currentColor"
											d="M33.25,18.06H21.33l2.84-2.83a1,1,0,1,0-1.42-1.42L17.5,19.06l5.25,5.25a1,1,0,0,0,.71.29,1,1,0,0,0,.71-1.7l-2.84-2.84H33.25a1,1,0,0,0,0-2Z"></path>
										<path
											fill="currentColor"
											stroke="currentColor"
											d="M29,16h2V6.68A1.66,1.66,0,0,0,29.35,5H27.08V7H29Z"></path>
										<path
											fill="currentColor"
											stroke="currentColor"
											d="M29,31H7V7H9V5H6.64A1.66,1.66,0,0,0,5,6.67V31.32A1.66,1.66,0,0,0,6.65,33H29.36A1.66,1.66,0,0,0,31,31.33V22.06H29Z"></path>
										<rect x="0" y="0" width="36" height="36" fill-opacity="0" />
									</svg>
									<div>Copy to clipboard</div>
								</button>
								<input
									type="hidden"
									data-testId="join_game_link"
									value={link}
								/>
							</div>
						</span>
					) : (
						<table className="host_player_list">
							<thead>
								<tr>
									<th>Player</th>
									<th>Team</th>
									<th>Remove</th>
									<th>Connected</th>
								</tr>
							</thead>
							{players.list.length === 0 && (
								<tr>
									<td colSpan={3}></td>
								</tr>
							)}
							<tbody>
								{players.list.map(player => (
									<tr>
										<td>
											<div
												className="player_item"
												data-player-item={player.name}>
												{player.name}
											</div>
										</td>
										<td>{player_teams[player.name] || '-'}</td>
										<td>
											<button onClick={() => remove_player(player.name)}>
												X
											</button>
										</td>
										<td>
											<div
												className={
													player.connected
														? 'socket_connected'
														: 'socket_disconnected'
												}></div>
										</td>
									</tr>
								))}
							</tbody>
						</table>
					)}
				</div>
			</div>
		</>
	)

	const controls = (
		<>
			<button type="submit" onClick={start_game}>
				Start game
			</button>
			{questions_and_rounds_enabled ? (
				<button
					className="alternative"
					onClick={() => send({type: Host_Event_Name.setup_game_structure})}>
					Back: setup questions
				</button>
			) : (
				<></>
			)}
			<button className="negative" onClick={send_cancel_game}>
				Cancel game
			</button>
		</>
	)

	return (
		<Standard_Page_Layout
			header={header}
			content={content}
			controls={controls}
		/>
	)
}
