import {Base_Props} from '../App/App'
import {Host_Event_Name} from '../../../common/src/state/Event_And_State_Names'
import {Standard_Page_Layout} from '../Components/Standard_Page_Layout'

export function Host_Game_Finished(props: Base_Props) {
	const {game_name} = props.context
	const {send} = props

	const send_close_game = () => send({type: Host_Event_Name.close_game})

	const header = (
		<>
			<div className="page_header">Finished</div>
			<div className="sub_header">
				congratulations to the winner of game: {game_name}!
			</div>
		</>
	)
	const content = <></>

	const controls = (
		<>
			<button onClick={send_close_game}>Close game</button>
		</>
	)
	return (
		<Standard_Page_Layout
			header={header}
			content={content}
			controls={controls}
		/>
	)
}
