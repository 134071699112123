import './Host_Registration.css'
import {Client_Event_Name} from '../../../common/src/state/Event_And_State_Names'
import {Base_Props} from '../App/App'
import {Controller, useForm} from 'react-hook-form'
import {Standard_Page_Layout} from '../Components/Standard_Page_Layout'
import {Modal_Focus} from '../Components/Modal_Focus/Modal_Focus'
import 'react-toggle/style.css'
import Toggle from 'react-toggle'
import { useEffect } from 'react'
import { Help_Tips, show_help_tip } from '../Help_Tips/Help_tips'

type FormValues = {
	game_name: string
	host_email: string
	teams_enabled: boolean
	questions_and_rounds_enabled: boolean
}

export function Host_Registration(props: Base_Props) {

	useEffect(() => {
		if (context.is_trial) {
			show_help_tip('intro')
		}
	})

	const {context, send} = props
	const {
		register,
		control,
		handleSubmit,
		formState: {errors}
	} = useForm<FormValues>({
		defaultValues: {
			teams_enabled: true,
			questions_and_rounds_enabled: true,
		}
	})
	
	const onSubmit = handleSubmit(data =>
		send({
			type: context.is_trial
				? Client_Event_Name.register_trial_host
				: Client_Event_Name.register_host,
			detail: {is_trial: context.is_trial, ...data},
		}),
	)

	const show_teams_tip = () => {
		
		if (context.is_trial) {
			window.setTimeout(() => {
				show_help_tip('teams')
			}, 50)
			
		}
	}

	const show_qs_and_rs_tip = () => {
		show_help_tip('qs_and_rs')
	}

	const header = (
		<>
			<div className="page_header">Create game</div>
			<div className="sub_header">lets get you started</div>
		</>
	)
	const content = (
		<>
			<form id="host_reg_form" onSubmit={onSubmit}>
				<div className="form_row">
					<Modal_Focus center={true}>
						<label>Game name:</label>
						<input
							className={errors.game_name ? 'validation_warn' : ''}
							id="host_reg_form_game_name"
							placeholder="enter a game name"
							{...register('game_name', {
								required: {value: true, message: 'game name is required'},
								maxLength: {
									value: 20,
									message: 'game name is too long, max 20',
								},
							})}
						/>
						<div className="show_in_modal validation_error">
							{errors.game_name?.message}
						</div>
					</Modal_Focus>
				</div>
				<div className="form_row">
					<Modal_Focus>
						<span>
							<label data-help-tip="intro">Host email:</label>
							<input
								className={errors.host_email ? 'validation_warn' : ''}
								{...register('host_email', {
									required: {
										value: !context.is_trial,
										message: 'required for verification',
									},
									maxLength: {value: 100, message: 'too long'},
									minLength: {value: 6, message: 'too short'},
									pattern: {
										value:
											/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
										message: 'invalid email',
									},
								})}
								onBlur={show_teams_tip}
								placeholder="enter your email"
							/>
							<div className="show_in_modal validation_error">
								{errors.host_email?.message}
							</div>
						</span>
					</Modal_Focus>
					<Help_Tips id="intro">
						<div>
							<p>
								First you need to enter a game name and your email where we'll send you a verification code.
							</p>
							<p>
								you get two free games then after that you
								can buy a game for 30 cents.
							</p>
						</div>
					</Help_Tips>
				</div>

				<h2>Game options</h2>
				<div className="form_row">
					<label 
						data-help-tip="teams"
						className="label_after" 
						htmlFor="host_reg_teams_enabled">
						Teams
					</label>
					<Controller
						control={control}
						name="teams_enabled"
						render={({field: {value, onChange}}) => (
							<Toggle
								id="host_reg_teams_enabled"
								data-testId="host_reg_teams_enabled"
								icons={{
									checked: null,
									unchecked: null,
								}}
								checked={value}
								defaultChecked={true}
								className="pink-toggle"
								onChange={onChange}
							/>
						)}
					/>
				</div>
				<Help_Tips button_action={show_qs_and_rs_tip} id="teams">
					<div>
						<p>
							Teams - are your players playing in teams? If so then you can leave this turned on. Otherwise turn it off
							and players will get their own individual scores.
						</p>
					</div>
				</Help_Tips>
				<div className="form_row">
					<label
						data-help-tip="qs_and_rs"
						className="label_after"
						htmlFor="host_reg_questions_and_rounds_enabled">
						Questions and Rounds
					</label>
					<Controller
						control={control}
						name="questions_and_rounds_enabled"
						render={({field: {value, onChange}}) => (
							<Toggle
								id="host_reg_questions_and_rounds_enabled"
								data-testId="host_reg_questions_and_rounds_enabled"
								icons={{
									checked: null,
									unchecked: null,
								}}
								defaultChecked={true}
								checked={value}
								className="pink-toggle"
								onChange={onChange}
							/>
						)}
					/>
				</div>
				<Help_Tips id="qs_and_rs">
					<div>
						<p>
							Questions and Rounds - Leave this turned on and we'll take you through adding questions and rounds to your game. 
							If you have questions elsewhere and you just want to use +/- score buttons manually then turn this toggle off.
						</p>
						<p>
							When you're ready, tap on the Start setup button below
						</p>
					</div>
				</Help_Tips>
			</form>
		</>
	)

	const controls = (
		<>
			<button type="submit" form="host_reg_form">
				Start setup
			</button>
			<button className="negative" onClick={() => history.back()}>
				Cancel game
			</button>
		</>
	)
	return (
		<Standard_Page_Layout
			header={header}
			content={content}
			controls={controls}
		/>
	)
}
