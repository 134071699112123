export enum Client_Event_Name {
	start_player_registration = 'start_player_registration',
	start_host_trial_registration = 'start_host_trial_registration',
	start_host_registration = 'start_host_registration',
	register_player = 'register_player',
	register_trial_host = 'register_trial_host',
	register_host = 'register_host',
	toggle_trial = 'toggle_trial',
	verify = 'verify',
	add_team = 'add_team',
	start_game = 'start_game',
	join_team = 'join_team',
	buzz = 'buzz',
	test_buzz = 'test_buzz',
	clear_buzz = 'clear_buzz',
	turn_on_buzzer = 'turn_on_buzzer',
	leave_game = 'leave_game',
	get_player_status = 'get_player_status',
	player_reconnect = 'player_reconnect',
	reset_game = 'reset_game',
	quit = 'quit',
	clear_error = 'clear_error',
	navigate_back = 'navigate_back',
	cancel_game = 'cancel_game',
	buy_games = 'buy_games',
}

export enum Host_Event_Name {
	setup_game_structure = 'setup_game_structure',
	client_side_error = 'client_side_error',
	get_host_status = 'get_host_status',
	remove_team = 'remove_team',
	setup_game = 'setup_game',
	add_sample_questions = 'add_sample_questions',
	add_round = 'add_round',
	add_question = 'add_question',
	swap_questions = 'swap_questions',
	swap_rounds = 'swap_rounds',
	remove_round = 'remove_round',
	remove_question = 'remove_question',
	set_round_name = 'set_round_name',
	set_question_text = 'set_question_text',
	set_answer_text = 'set_answer_text',
	set_question_points = 'set_question_points',
	set_question_deduct_points = 'set_question_deduct_points',
	update_game_structure = 'update_game_structure',
	answer_is_correct = 'answer_is_correct',
	answer_is_incorrect = 'answer_is_incorrect',
	finish_game = 'finish_game',
	remove_player = 'remove_player',
	set_score = 'set_score',
	set_player_score = 'set_player_score',
	return_to_setup = 'return_to_setup',
	close_game = 'close_game',
}

export enum Server_Event_Name {
	game_update = 'game_update',
	host_update = 'host_update',
	game_structure_update = 'game_structure_update',
	game_setup_unverified = 'game_setup_unverified',
	game_in_setup = 'game_in_setup',
	game_started = 'game_started',
	game_expired = 'game_expired',
	game_returned_to_setup = 'game_returned_to_setup',
	game_finished = 'game_finished',
	game_closed = 'game_closed',
	invalid_message = 'invalid_message',
	verification_code_incorrect = 'verification_code_incorrect',
	verify_and_continue = 'verify_and_continue',
	ran_out_of_games = 'ran_out_of_games',
	update_remaining_games_count = 'update_remaining_game_count',
	player_registered = 'player_registered',
	host_registered = 'host_registered',
	player_buzzed = 'player_buzzed',
	game_doesnt_exist = 'game_doesnt_exist',
	server_event_error = 'server_event_error',
	player_removed = 'player_removed',
	buzzer_test = 'buzzer_test',
	socket_disconnected = 'socket_disconnected',
	trial_expired = 'trial_expired',
}

export enum Server_State_Name {
	/** state where the machine initialised but nothing has happened yet */
	init = 'init',
	/** state where the game is initialised but the host hasn't verified by email yet */
	unverified = 'unverified',
	/** state where the host has run out of games and needs to buy more */
	ran_out_of_games = 'ran_out_of_games',
	/** state before the game starts where the host is inviting players in and setting up teams */
	pre_game_setup = 'pre_game_setup',
	/** state where game is in play */
	in_play = 'in_play',
	/** state after the game has finished */
	finished = 'finished',
	/** ready to be destroyed */
	closed = 'closed',
}
