import { Client_Config_Autotest } from "./Client_Config.autotest";
import { Client_Config_Localdev } from "./Client_Config.localdev";
import { Client_Config_Production } from "./Client_Config.production";
import { Client_Environment_Config } from "./Client_Environment_Config_Type";

export const Environments: {[index: string]: Client_Environment_Config} = {
	localdev: Client_Config_Localdev,
    autotest: Client_Config_Autotest,
    production: Client_Config_Production,
}

const Env_Urls: { [index: string]: string } = {
    'http://192.168.0.2:3000': 'localdev',
    'http://localhost:3000': 'localdev',
    'http://localhost:3001': 'autotest',
    'https://quiz-buzz.com': 'production',
}

let env: Client_Environment_Config

export function get_environment() {
    
    // Determine which environment we are in using the URL:
    const loc = window.location.protocol + "//" + window.location.hostname + (window.location.port ? `:${window.location.port}` : '')
    if (!env) {
        const env_name = Env_Urls[loc]
        env = Environments[env_name]
        if (!env) {
            throw new Error('no environment found for URL: ' + loc)
        }
    }
    return env
}