import './style/normalize.css'
import './style/Page_Layout.css'
import './style/App.css'
import './style/User_Advice.css'
import './style/Checkbox.css'
import qb_icon from '../../assets/qb-icon.svg'
import {Splash_Page} from '../Splash_Page/Splash_Page'
import {
	PlayerContext,
	Client_State_Name,
	playerMachine,
} from '../State/player_machine'
import {useActor} from '@xstate/react'
import {AnyEventObject} from 'xstate'
import {Player_Game_Setup} from '../Player_Game_Setup/Player_Game_Setup'
import {Player_Registration} from '../Player_Registration/Player_Registration'
import {Host_Registration} from '../Host_Registration/Host_Registration'
import {Host_Game_Setup} from '../Host_Game_Setup/Host_Game_Setup'
import {Host_In_Game} from '../Host_In_Game/Host_In_Game'
import {Player_In_Game} from '../Player_In_Game/Player_In_Game'
import {useEffect} from 'react'
import {Key_For_XState_Persistence} from './main'
import {Player_Machine_Snapshot} from '../State/types'
import {Connection_Closed} from '../Connection_Closed/Connection_Closed'
import {Websocket_Event_Names} from '../State/websockets_machine'
import {Client_Event_Name} from '../../../common/src/state/Event_And_State_Names'
import {Host_Setup_Game_Structure} from '../Host_Setup_Game_Structure/Host_Setup_Game_Structure'
import {Host_Game_Finished} from '../Host_Finished/Host_Finished'
import {Error_Boundary} from './Error_Boundary'
import {Host_Unverified} from '../Host_Unverified/Host_Unverified'
import {Modal_Popup} from '../Modal_Popup/Modal_Popup'
import {Toaster} from 'react-hot-toast'
import {Player_Finished} from '../Player_Finished/Player_Finished'
import {Host_Ran_Out_Of_Games} from '../Host_Ran_Out_Of_Games/Host_Ran_Out_Of_Games'
import '../Help_Tips/Help_Tips.css'
import { app_version, app_version_hash } from '../../../common/version'

export interface Base_Props {
	send: (event: AnyEventObject) => void
	context: PlayerContext
}

// This snapshot type was just copied from XState to satisfy the typescript checker.
interface App_Props {
	snapshot?: Player_Machine_Snapshot
}

export function App(props: App_Props) {
	// Initiate the player machine, pass in a snapshot if it
	// was persisted to storage.
	const [state, send, service] = useActor(playerMachine, {
		snapshot: props.snapshot,
	})

	if (state.value === 'splash' && window.location.hash.length === 5) {
		send({type: 'start_player_registration', detail: {source: 'app-load'}})
	}

	window.addEventListener('hashchange', () => {
		if (state.value === 'splash' && window.location.hash.length === 5) {
			send({
				type: 'start_player_registration',
				detail: {source: 'url-hash-change-handler'},
			})
		}
	})

	const show_version = () => {
		(document.querySelector('#app-version') as HTMLDivElement).style.color = "white"
		window.setTimeout(() => {
			(document.querySelector('#app-version') as HTMLDivElement).style.color = "#00000000";
		}, 800)
	}

	const context: PlayerContext = state.context

	// These effects are for the first time loading the app only:
	useEffect(() => {
		send({type: Websocket_Event_Names.websocket_closed})

		// Prevent back button and send an event instead,
		// TODO: in future be selective about this by adding the pushState call in to the state machine
		// so that you can back through states and then out of the site completely
		history.pushState({state: 'application started'}, 'Quiz Buzz', '')
		addEventListener('popstate', () => {
			send({type: Client_Event_Name.navigate_back})
			history.pushState({state: 'application started'}, 'Quiz Buzz', '')
		})

		service.subscribe(snapshot => {
			sessionStorage.setItem(
				Key_For_XState_Persistence,
				JSON.stringify(service.getPersistedSnapshot()),
			)
			console.log('new state: ', snapshot.value, snapshot)
		})

		// I dont want this effect running on every state change, only on init. So I need to read
		// more about react-hooks/exhaustive-deps and why running a subscription from an effect is bad.
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [service])

	return (
		<div className="app" data-help-tips-base="base">
			<div id="m-space-measure">m</div>
			<Error_Boundary>
				<img className="logo-home" src={qb_icon} />
				{state.value === Client_State_Name.splash && (
					<Splash_Page send={send} context={context} />
				)}
				{state.value === Client_State_Name.registering_player && (
					<Player_Registration send={send} context={context} />
				)}
				{state.value === Client_State_Name.player_game_setup && (
					<Player_Game_Setup send={send} context={context} />
				)}
				{state.value === Client_State_Name.player_in_game && (
					<Player_In_Game send={send} context={context} />
				)}
				{state.value === Client_State_Name.player_finished && (
					<Player_Finished send={send} context={context} />
				)}
				{state.value === Client_State_Name.registering_host && (
					<Host_Registration send={send} context={context} />
				)}
				{state.value === Client_State_Name.host_game_unverified && (
					<Host_Unverified send={send} context={context} />
				)}
				{state.value === Client_State_Name.ran_out_of_games && (
					<Host_Ran_Out_Of_Games send={send} context={context} />
				)}
				{state.value === Client_State_Name.host_setup_game_structure && (
					<Host_Setup_Game_Structure send={send} context={context} />
				)}
				{state.value === Client_State_Name.host_game_setup && (
					<Host_Game_Setup send={send} context={context} />
				)}
				{state.value === Client_State_Name.host_in_game && (
					<Host_In_Game send={send} context={context} />
				)}
				{state.value === Client_State_Name.host_finished && (
					<Host_Game_Finished send={send} context={context} />
				)}
				{state.value === Client_State_Name.connection_closed && (
					<Connection_Closed send={send} context={context} />
				)}
				<Modal_Popup send={send} context={context} />
				<Toaster position="bottom-center" />
				<div id="app-version" className="app-version" onClick={show_version}>
					<div>App Version: {app_version}</div>
					<div>App Hash: {app_version_hash}</div>
				</div>
			</Error_Boundary>
		</div>
	)
}

export default App
