import {fromCallback, type EventObject} from 'xstate'

function getWebSocket(url: string): WebSocket {
	return new WebSocket(url)
}

export enum Websocket_Event_Names {
	connect_websocket = 'connect_websocket',
	websocket_opened = 'websocket_opened',
	websocket_closed = 'websocket_closed',
	websocket_error = 'websocket_error',
	client_request_disconnect = 'client_request_disconnect',
}

export const websocketCallback = fromCallback(({sendBack, receive}) => {
	let protocol = 'wss://'
	if (document.location.protocol === 'http:') {
		protocol = 'ws://'
	}
	let port = ''
	if (document.location.port) {
		port = ':' + document.location.port
	}

	let websocket: WebSocket

	const connect = () => {
		console.log('init websocket')
		websocket = getWebSocket(
			`${protocol}${window.location.hostname}${port}/api/v1`,
		)

		websocket.onopen = function (this: WebSocket) {
			sendBack({type: Websocket_Event_Names.websocket_opened})
		}
		websocket.onmessage = function (this: WebSocket, ev: MessageEvent) {
			try {
				console.log('event from server: ', ev.data)
				const data = JSON.parse(ev.data)
				sendBack(new CustomEvent(data.type, {detail: data.detail}))
			} catch (e) {
				sendBack(new CustomEvent('invalid_server_response'))
			}
		}
		websocket.onclose = function (this: WebSocket) {
			sendBack({type: Websocket_Event_Names.websocket_closed})
		}
		websocket.onerror = function (this: WebSocket, ev: Event) {
			console.log('websocket error ', ev)
			sendBack({type: Websocket_Event_Names.websocket_error, detail: ev})
		}
	}

	// When this websocket actor receives an XSTATE event from a
	// parent actor it will send it over the WEBSOCKET connection
	receive((event: EventObject) => {
		if (event.type === Websocket_Event_Names.client_request_disconnect) {
			websocket.close()
			sendBack({type: 'websocket_closed'})
		}
		if (event.type === Websocket_Event_Names.connect_websocket) {
			connect()
		} else {
			if (websocket) {
				websocket.send(format_event_for_websocket(event))
			}
		}
	})
})

function format_event_for_websocket(event: EventObject) {
	return JSON.stringify({
		type: event.type,
		detail: (event as CustomEvent).detail,
	})
}
