import './Splash_Page.css'
import {Base_Props} from '../App/App'
import {Standard_Page_Layout} from '../Components/Standard_Page_Layout'

export function Splash_Page(props: Base_Props) {

	const header = (
		<>
			<div className="page_header">Quiz Buzz</div>
			<div className="sub_header">Game show buzzers for everyone</div>
		</>
	)

	const content = (
		<>
			<div
				id="host_button"
				className="round_long_button green"
				onClick={() => props.send({type: 'start_host_trial_registration'})}>
				<div className="round_button">
					<span>1st</span>
				</div>
				<div className="round_long_button_text">
					<span>
						First time?
					<div className="long_button_subtext">Take the tour!</div>
					</span>
				</div>
			</div>

			<div
				id="host_button"
				className="round_long_button pink"
				onClick={() => props.send({type: 'start_host_registration'})}>
				<div className="round_button">
					<span>Host</span>
				</div>
				<div className="round_long_button_text">
					<span>Host a game</span>
				</div>
			</div>

			<a href="/about/">
				<div id="about_button" className="round_long_button blue">
					<div className="round_button">
						<span>?</span>
					</div>
					<div className="round_long_button_text">
						<span>How to play</span>
					</div>
				</div>
			</a>

			{/* <div id="try_button" className="round_long_button pink" onClick={() => props.send({ type: 'start_host_trial_registration'})}>
            <div className="round_button"><span>Try</span></div>
            <div className="round_long_button_text">
                <span>Try it out free</span>
            </div>
        </div>



        <div id="join_button" className="round_long_button green" onClick={() => props.send({ type: 'start_player_registration'})}>
            <div className="round_button"><span>Join</span></div>
            <div className="round_long_button_text"><span>Enter a game code</span></div>
        </div> */}
		</>
	)

	const controls = <></>

	return (
		<Standard_Page_Layout
			header={header}
			content={content}
			controls={controls}
		/>
	)
}
