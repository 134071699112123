import './Host_In_Game.css'
import {
	Client_Event_Name,
	Host_Event_Name,
} from '../../../common/src/state/Event_And_State_Names'
import {Base_Props} from '../App/App'
import {Player, Team} from '../../../common/src/models/Base_Concepts'
import {Standard_Page_Layout} from '../Components/Standard_Page_Layout'

export function Host_In_Game(props: Base_Props) {
	const {
		send,
		context: {
			game_name,
			teams,
			players,
			teams_enabled,
			questions_and_rounds_enabled,
			whos_buzzer_is_active,
			game_structure,
			question_num,
			round_num,
		},
	} = props
	const clear_buzz = () => send({type: Client_Event_Name.clear_buzz})
	const finish_game = () => send({type: Host_Event_Name.finish_game})
	const return_to_setup = () => send({type: Host_Event_Name.return_to_setup})
	const modify_team_score = (team: Team, diff: number) =>
		send({
			type: Host_Event_Name.set_score,
			detail: {
				team_name: team.name,
				score: team.score + diff,
			},
		})
	const modify_player_score = (player: Player, diff: number) =>
		send({
			type: Host_Event_Name.set_player_score,
			detail: {
				player_name: player.name,
				score: player.score + diff,
			},
		})

	let question
	if (
		game_structure.rounds &&
		game_structure.rounds[round_num]?.questions[question_num]
	) {
		question = game_structure.rounds[round_num].questions[question_num]
	}

	const send_correct = () => send({type: Host_Event_Name.answer_is_correct})
	const send_incorrect = () => send({type: Host_Event_Name.answer_is_incorrect})

	const buzzer_active = !!whos_buzzer_is_active

	const header = (
		<>
			<div className="page_header">Game on!</div>
			<div className="sub_header">game: {game_name}</div>
		</>
	)

	const content = (
		<>
			<div className="buzzed_section">
				{questions_and_rounds_enabled ? (
					<button
						disabled={!buzzer_active}
						className="negative"
						onClick={send_incorrect}>
						Incorrect
					</button>
				) : (
					<></>
				)}

				<div className="who_buzzed_frame">
					<div className="buzzed_label">
						<span>Buzzed:</span>
					</div>
					<div
						data-testId="who_buzzed"
						className={`who_buzzed ${whos_buzzer_is_active ? 'active' : ''}`}>
						{whos_buzzer_is_active ? whos_buzzer_is_active : ''}
					</div>
				</div>
				{questions_and_rounds_enabled ? (
					<button disabled={!buzzer_active} onClick={send_correct}>
						Correct
					</button>
				) : (
					<button disabled={!buzzer_active} onClick={send_correct}>
						Clear buzzers
					</button>
				)}
			</div>

			{question ? (
				<div className="question_display">
					<div className="question_text">
						Question: {question.question_text}
					</div>
					<div className="answer_text">Answer: {question.answer_text}</div>
					<div className="question_display_points">
						Points: {question.points}
					</div>
					<div className="question_display_incorrect_points">
						Deduct if incorrect: {question.incorrect_points}
					</div>
				</div>
			) : (
				''
			)}

			{teams.list.map((team, i) => (
				<div data-testId={`team row ${team.name}`} className="team_container">
					<div className="team_left_side">
						<div className="team_name">{team.name}</div>
						<div className="scores_box">
							<div className="score_buttons">
								<div>
									<button
										data-testId={`team_score_plus_1_${i}`}
										onClick={() => modify_team_score(team, 1)}>
										+1
									</button>
									<button
										data-testId={`team_score_minus_1_${i}`}
										onClick={() => modify_team_score(team, 5)}>
										+5
									</button>
								</div>
								<div>
									<button
										data-testId={`team_score_plus_5_${i}`}
										className="negative"
										onClick={() => modify_team_score(team, -1)}>
										-1
									</button>
									<button
										data-testId={`team_score_minus_5_${i}`}
										className="negative"
										onClick={() => modify_team_score(team, -5)}>
										-5
									</button>
								</div>
							</div>
						</div>
					</div>
					<div className="team_center">
						<div>Score:</div>
						<div data-testId={`team_score_${i}`} className="score_box">
							{team.score}
						</div>
					</div>
					<div className="team_right_side">
						<div>Team members:</div>
						<div className="team_players_box">
							{team.members.list.map(member => (
								<div
									className={`player_item ${
										whos_buzzer_is_active === member.name ? 'buzzed' : ''
									}`}>
									{member.name}
								</div>
							))}
						</div>
					</div>
				</div>
			))}

			{!teams_enabled && (
				<>
					<table className="host_player_list">
						<thead>
							<tr>
								<th>Player</th>
								<th>Score</th>
								<th>Connected</th>
								<th>+/-</th>
							</tr>
						</thead>
						{players.list.length === 0 && (
							<tr>
								<td colSpan={3}></td>
							</tr>
						)}
						<tbody>
							{players.list.map((player, i) => (
								<tr>
									<td>
										<div className="player_item" data-player-item={player.name}>
											{player.name}
										</div>
									</td>
									<td
										data-testId={`player_score_${i}`}
										className="player_score">
										{player.score}
									</td>
									<td>
										<div
											data-testId={`player_connected_${i}`}
											className={
												player.connected
													? 'socket_connected'
													: 'socket_disconnected'
											}></div>
									</td>
									<td>
										<button
											data-testId={`player_neg_button_${i}`}
											className="negative"
											onClick={() => modify_player_score(player, -5)}>
											-5
										</button>
										<button
											data-testId={`player_pos_button_${i}`}
											onClick={() => modify_player_score(player, +5)}>
											+5
										</button>
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</>
			)}
		</>
	)

	const controls = (
		<>
			{questions_and_rounds_enabled ? (
				<button onClick={clear_buzz}>Clear buzzers</button>
			) : (
				<></>
			)}
			<button className="alternative" onClick={return_to_setup}>
				Pause Game
			</button>
			<button className="negative" onClick={finish_game}>
				Finish game
			</button>
		</>
	)

	return (
		<Standard_Page_Layout
			header={header}
			content={content}
			controls={controls}
		/>
	)
}
